.card-background .Addrider-card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 1rem auto;
  max-width: 700px;
  height: auto;
  text-align: center;
  padding: 40px;
  border-radius: 44px;
  border-style: solid;
  border-width: 0.1px;
  border-color: #e6e6e6;
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
}
.card-background1 .Addrider-card1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 1rem auto;
  max-width: 300px;
  height: 300px;
  text-align: center;
  padding: 40px;
  border-radius: 44px;
  border-style: solid;
  border-width: 0.1px;
  border-color: #e6e6e6;
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 200px;
  margin-right: 450px;
}
.card-background::-webkit-scrollbar {
  display: none !important;
}
.card-background1::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.card-background {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for IE, Edge and Firefox */
.card-background1 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.frm {
  width: 70%;
  margin: 0 auto;
}
.Addrider-card::-webkit-scrollbar {
  display: none;
}
.Addrider-card1::-webkit-scrollbar {
  display: none;
}
.card-background .Addrider-card .card-head {
  display: flex;
  align-items: center;
}

.card-background .Addrider-card h1 {
  position: relative;
  margin-bottom: 0px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;

  height: 43px;
  font: normal normal bold 30px Poppins;
  color: #5016bf;
}
.card-background .Addrider-card img {
  height: 24px;
  width: 24px;
}

.card-background .Addrider-card .form-card {
  margin-top: 74px;
  position: relative;
}

.card-background .Addrider-card .input {
  position: relative;
  width: 100%;
  height: 45px;
  margin-top: 17px;
  border: 0.5px solid #afafaf;
  border-radius: 44px;
  box-sizing: border-box;
  font: normal 16px Poppins;
  letter-spacing: 0px;
  color: #afafaf;
  padding-left: 22px;
  outline: none;
}

.card-background .Addrider-card .login-submit {
  position: relative;
  width: 45%;
  height: 50px;
  margin-top: 35px;
  background: #5016bf;
  border-radius: 44px;
  font: normal 500 16px Poppins;
  border: none;
  color: #ffffff;
  font-size: 16px;
}

.card-background .Addrider-card .upload-pic {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 34px;
}
.card-background .Addrider-card .upload-pic .uploadTxt {
  /* margin-top: 6.5px;
  margin-bottom: 0px; */
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

/* .card-background .Addrider-card .upload-pic .uploadBtn::-webkit-file-upload-button {
    visibility: hidden;
  }
.card-background .Addrider-card .upload-pic .uploadBtn{
    font-size: 20px;
    
} */
/* #custom-button {
    padding: 10px;
    color: white;
    background-color: #009578;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
  }
  
  #custom-button:hover {
    background-color: #00b28f;
  }
  
  #custom-text {
    margin-left: 10px;
    font-family: sans-serif;
    color: #aaa;
  } */

.card-background .Addrider-card .upload-pic .uploadbtn {
  width: 157px;
  height: 49px;
  font: normal 600 16px Poppins;
}
.card-background1 .Addrider-card1 .upload-pic1 .uploadbtn1 {
  width: 200px;
  height: 49px;
  font: normal 600 16px Poppins;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .card-background .Addrider-card {
    width: 85%;
    vertical-align: middle; /*Vertically centered*/
  }
  .card-background .Addrider-card .form-card {
    width: 100%;
    margin-top: 50px;
  }
  .card-background .Addrider-card .input {
    box-sizing: border-box;
    width: 100%;
    font: normal 16px Poppins;
    height: 45px;
    border-radius: 35px;
  }

  .card-background .Addrider-card .login-submit {
    box-sizing: border-box;
    width: 100%;
    font: normal 500 16px Poppins;
    height: 60px;
    border-radius: 35px;
  }

  .login-submit1 {
    box-sizing: border-box;
    width: 100%;
    font: normal 500 16px Poppins;
    height: 60px;
    border-radius: 35px;
  }

  .card-background .Addrider-card .reset {
    font: normal 16px Poppins;
  }

  .card-background .Addrider-card {
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .frm {
    width: 100%;
    margin: 0 auto;
  }
}
.Addrider-card::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 570px) {
  .card-background .Addrider-card .upload-pic .uploadTxt {
    font-size: 15px;
  }
  .card-background .Addrider-card .upload-pic .uploadbtn {
    width: 120px;
    height: 30px;
    font: normal 600 16px Poppins;
  }
  .card-background .Addrider-card .upload-pic {
    padding: 10px;
  }
  .card-background .Addrider-card h1 {
    height: 20px;
    font: normal normal bold 25px Poppins;
  }
  .card-background .Addrider-card .input {
    font: normal 16px Poppins;
    height: 60px;
  }
  .card-background .Addrider-card .login-submit {
    font: normal 20px Poppins;
    height: 60px;
    margin-top: 15px;
  }
  .card-background {
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.5);
    background-attachment: fixed;
  }
  .card-background .Addrider-card {
    width: 78%;
  }
}