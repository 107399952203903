.Heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #5016bf;
  font: normal normal bold 30px/46px Poppins;
  letter-spacing: 0px;
}
.job-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sheetButton {
  background: #5016bf 0% 0% no-repeat padding-box;
  border-radius: 44px;
  border: 0px solid #5016bf;
  width: 140px;
  height: 50px;
  align-items: center;
  font: normal normal 600 20px/36px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}
.generateButton{
  background: #5016bf 0% 0% no-repeat padding-box;
  border-radius: 44px;
  border: 0px solid #5016bf;
  width: 300px;
  height: 50px;
  align-items: center;
  font: normal normal 600 20px/36px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}
.generator-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.generator-text {
  font: normal normal 600 18px/23px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.gen-label {
  font-size: 15px;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 30px;
  font-family: Poppins;
  margin-top: -24px;
  margin-bottom: 10px;
}
.deactive {
  opacity: 0.35;
}
.gen-container2 {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  column-gap: 10px;
}
.gen-form {
  width: 150px;
  height: 40px;
  border-radius: 44px;
  border: 0.5px solid #afafaf;
}
.gen-form2 {
  width: 150px;
  height: 40px;
  border-radius: 44px;
  font-size: 13px;
  border: 0.5px solid #afafaf;
}
.gen-button {
  border: 0px solid #fff;
  background: #fff;
  width: 5px;
  height: 5px;
  font-size: 30px;
  color: #501abf;
  margin-top: -4px;
  font-weight: 400;
}
.gen-container3 {
  display: flex;
  flex-direction: row;
  column-gap: 25px;
}
.gen-text {
  text-align: left;
  font: normal normal medium 18px/25px Poppins;
  letter-spacing: 0px;
  color: #cecece;
  margin-top: 7px;
}
.field-container{
  display: flex;
  flex-direction: row;
  column-gap: 17px;
  margin-left: 5px;
}
.field-input{
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: 1px solid #CECECE;
  width: 120px;
  height: 25px;
  border-radius: 0px;
  margin-bottom: 10px;
  font-size: 13px;
}
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.gen-error{
  color: red;
  font-size: 13px;
  margin-top: -200px;
}
.gen-error1{
  color: red;
  font-size: 13px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.excel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  margin-left: 90%;
  color: #fff;
  background-color: #198754;
  border: #198754;
  margin: 12px;
  height: 45px;
  font-size: 16px;
  font-family: Poppins;
  border-radius: 50px;
}
.container15{
  display: flex;
  flex-direction: row;
}
.payment-button{
  background-color: #5016bf;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 30px;
  margin-top: 16px;
}
.switch{
  margin: 20px 20px 20px 50px;
}