.form-select {
  width: 100%;
  height: 46px;
  border: 1px solid #afafaf;
  border-radius: 100px;
  padding-left: 22px;
  padding-right: 22px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  letter-spacing: 0px;
  background-position: right 22px center !important;
  background-image: url("../../../assets/down.svg");

  letter-spacing: 0px;
  color: #212529aa;
  text-align: left;
  font: normal 14px Poppins;
  font-size: 14px;
}
@media only screen and (max-width: 800px) {
  .form-select {
    width: 100%;
    height: 45px;
    border: 1px solid #ccc;
    border-radius: 100px;
    padding-left: 40px;
    padding-right: 40px;
    line-height: 1;
    box-sizing: border-box;
    outline: none;
    letter-spacing: 0px;
    background-position: right 52px center !important;
    background-image: url("../../../assets/down.svg");

    letter-spacing: 0px;
    color: #212529aa;
    text-align: left;
    font: normal 16px Poppins;
    font-size: 16px;
  }
}

select {
  border-right: 44px solid transparent;
}
select:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ccc !important;
}
.container1{
  margin-left: 5%;
}