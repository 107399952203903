.map-container {
    height: 100vh;
    width: 100%;
    position: relative;
  }
  
  .map-container > div {
    height: 100%;
    width: 100%;
  }
  
  @media only screen and (max-width: 768px) {
    .map-container {
      height: 50vh;
    }
  }
  