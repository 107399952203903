.css-1s2u09g-control,
.css-1pahdxg-control {
  width: 100% !important;
  min-height: 88px !important;
  border: 1px solid #ccc !important;
  border-radius: 50px !important;
}
.filter__dropdown-indicator {
  padding: 0px !important;
}
.filter__value-container {
  padding: 0px 8px 0px 0px !important;
  font-size: 16px;
}
.filter__value-container--has-value {
  padding: 8px 8px 8px 0px !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-26l39u-menu {
  background-color: aliceblue !important;
}
.css-14el2xx-placeholder {
  font-size: 16px !important;
}
.react-select-container {
  background-color: red !important;
  color: red !important;
}
.filter__control .css-73zj42-control {
  padding: 20px !important;
}
.css-41k5s4-control {
  padding: 20px !important;
}
