.excel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  margin-left: 85%;
  color: #fff;
  background-color: #198754;
  border: #198754;
  height: 45px;
  font-size: 16px;
  font-family: Poppins;
  border-radius: 50px;
}
