.setuphead {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 38px;
}
.btnaddnew {
  position: relative;
  margin-left: auto;
  width: 180px;
  height: 50px;
  border: 1px solid #501abf;
  border-radius: 44px;
  opacity: 1;
  color: #501abf;
  background-color: #ffffff;
}
.subhead {
  color: #501abf;
}

.btntext {
  color: #501abf;
  padding-right: 0px;
}
.policy {
  margin-left: 32px;
  margin-right: 52px;
  margin-top: 20px;
}

@media only screen and (max-width: 800px) {
  .mainNav .profile {
    /* padding-right: 20px; */
    padding-right: 0px;
  }

  .main .mainNav {
    /* width: 77%; */
    width: 92%;
  }

  .policy {
    margin-left: 0;
    margin-right: 20px;
    left: 3%;
    position: relative;
  }
  .btnaddnew {
    width: 148px;
    height: 46px;
    border-radius: 24px;
  }

  .setuphead {
    padding-left: 14px;
    padding-right: 20px;
    margin-top: 60px;
  }
  .mainNav .title h1 {
    margin-top: 14px;
  }
}
