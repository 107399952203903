.login-card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 532px;
  text-align: center;
}

.login-card h1 {
  position: relative;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  /* width: 83px; */
  height: 43px;
  font: normal normal bold 30px Poppins;
  color: #5016bf;
}

.login-card .frm {
  margin-top: 50px;
  position: relative;
}

.login-card .input {
  position: relative;
  width: 100%;
  height: 60px;
  margin-top: 17px;
  border: 0.5px solid #afafaf;
  border-radius: 44px;
  box-sizing: border-box;
  font: normal 30px Poppins;
  letter-spacing: 0px;
  color: #afafaf;
  padding-left: 44px;
  font-size: 16px;
  outline: #5016bf;
}

.login-card .login-submit {
  position: relative;
  width: 100%;
  height: 60px;
  margin-top: 35px;
  background: #5016bf;
  border-radius: 44px;
  font: normal 500 16px Poppins;
  color: #ffffff;
  max-width: 300px;
  border: 2px solid #5016bf;
}
.login-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem auto !important;
}
.login-submit:hover {
  color: #5016bf;
  background: #ffff !important;
  font-weight: 600;
}
.login-submit:hover > span {
  color: #5016bf !important;
}
.login-submit-loader {
  color: #fff !important;
  width: 24px !important;
  height: 24px !important;
}

.login-card .reset {
  position: relative;
  text-decoration: underline;
  font: normal 16px Poppins;
  letter-spacing: 0px;
  color: #5016bf !important;
  margin-top: 39px;
}
.reset-a {
  color: #5016bf !important;
}
/* Mobile responsive */

@media only screen and (max-width: 768px) {
  .login-card {
    width: 85%;
    vertical-align: middle; /*Vertically centered*/
  }
  .login-card .frm {
    width: 100%;
    margin-top: 50px;
  }
  .login-card .input {
    box-sizing: border-box;
    width: 100%;
    font: normal 16px Poppins;
    height: 60px;
    border-radius: 35px;
  }

  .login-card .login-submit {
    box-sizing: border-box;
    width: 100%;
    font: normal 600 16px Poppins;
    height: 60px;
    border-radius: 35px;
  }

  .login-card .reset {
    font: normal 16px Poppins;
  }
  .login-card {
    height: fit-content;
  }
}
