.rider-table {
  overflow-x: auto;
  top: 50px;
}

.tableHead2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 88px;
  position: relative;
  padding-right: 52px;
}
.table-view {
  overflow-x: auto;
  max-width: 1400px;
  margin: 2rem auto;
}
.table-view table,
th {
  border: 1px solid white;
  border-collapse: collapse;
}
.table-view table,
td {
  border-collapse: collapse;
}
tr:nth-child(odd) {
  background-color: #d6eeee;
}
.instruction {
  border: 1px solid #ccc;
  background-color: #fafafa;
  border-radius: 24px;
  padding: 3rem;
  box-shadow: #ccc;
}
@media only screen and (max-width: 800px) {
  .table-view {
    width: calc(1vw * 90);
  }
}

.rider-table-head table {
  border-width: 0px;
  border-spacing: 0;
  width: 100%;
}
.rider-table-head > th {
  background-color: #501abf;
}
.instruction {
  background-color: #fff !important;
}
.instruction > p > span {
  font-weight: 700;
  color: #000;
}
.headclass {
  left: 32px;
  position: relative;
}
.table-head {
  background-color: #fff !important;
  border: 1px solid !important;
}
.rider-table-head,
th,
td {
  padding: 0.5rem 0.4rem !important;
  color: #000;
  text-align: center !important;
  height: 60px;
}
th {
  color: #501abf;
  font: normal 400 16px Poppins;
  padding: 0px;
}
th,
td {
  text-align: left;
}
td {
  padding-top: 30px;
}

.tableimg {
  width: 40px;
  height: 40px;
}

.btnaddnew {
  position: relative;
  margin-left: auto;
  width: 222px;
  height: 69px;
  border: 1px solid #501abf;
  border-radius: 44px;
  opacity: 1;
  background-color: #ffffff;
}

.tableHead2 .css-1oaj67-MuiTypography-root {
  left: 3%;
}

@media only screen and (max-width: 800px) {
  .slider .navigation {
    z-index: 9;
  }

  .main .mainNav {
    top: 2.4%;
    width: 77%;
    left: 3%;
    align-items: center;
  }

  .mainNav .title {
    left: 0;
  }
  .mainNav .title h1 {
    font-size: 20px;
  }

  .mainNav .profile {
    padding-right: 30px;
  }

  .mainNav .profile .profilePic {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .mainNav .profile .drop img {
    margin-left: 15px;
  }
  .profilePic > img:nth-child(1) {
    width: 45px;
  }

  .mainNav .profile .subTitle {
    display: none;
  }

  .newFile2 {
    top: 20%;
    width: 80%;
  }

  .newFile2 form {
    margin-top: 50px;
  }
  .newFile2 form .input-div-input {
    height: 70px;
    border-radius: 35px;
    font: normal 21px Poppins;
    padding-right: 22px;
    padding-left: 25px;
  }
  .newFile2 form .input-div-botton {
    padding-right: 22px;
  }

  .newFile2 form .submit3 {
    height: 70px;
    border-radius: 35px;
    font: normal 21px Poppins;
  }

  .headclass {
    left: 3%;
  }

  .rider-table {
    left: 3%;
    margin-left: 0px;
  }
  .tableHead2 {
    padding-right: 80px;
  }
}
.active {
  background-color: #fff !important;
}
