.card-background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: center;
  text-align: center;
  top: 0px;
  right: 0;
  opacity: 1;
  backdrop-filter: blur(2px);
  z-index: 10;
}

.card-background .updatepolicy-card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 38.75%;
  height: fit-content;
  text-align: center;
  padding: 40px;
  border-radius: 44px;
  border-style: solid;
  border-width: 0.1px;
  border-color: #e6e6e6;
  background-color: #ffffff;
}
.card-background .updatepolicy-card .card-head {
  display: flex;
  align-items: center;
}

.card-background .updatepolicy-card h1 {
  position: relative;
  margin-bottom: 0px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;

  height: 43px;
  font: normal normal bold 30px Poppins;
  color: #5016bf;
}
.card-background .updatepolicy-card img {
  height: 24px;
  width: 24px;
}

.card-background .updatepolicy-card .form-card-new {
  margin-top: 74px;
  position: relative;
}

.card-background .updatepolicy-card .input {
  position: relative;
  width: 100%;
  height: 470px;
  margin-top: 17px;
  border: 0.5px solid #afafaf;
  border-radius: 44px;
  box-sizing: border-box;
  font: normal 30px Poppins;
  letter-spacing: 0px;
  color: #afafaf;
  padding-left: 44px;
}

.card-background .updatepolicy-card .login-submit {
  position: relative;
  width: 100%;
  height: 88px;
  margin-top: 35px;
  background: #5016bf;
  border-radius: 44px;
  font: normal 600 30px Poppins;
  border: none;
  color: #ffffff;
}

.card-background .updatepolicy-card .upload-pic {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 34px;
}
.card-background .updatepolicy-card .upload-pic .uploadTxt {
  margin-top: 6.5px;
  margin-bottom: 0px;
}

.card-background .updatepolicy-card .upload-pic .uploadbtn {
  width: 157px;
  height: 49px;
  font: normal 600 21px Poppins;
}

@media only screen and (max-width: 768px) {
  .card-background .updatepolicy-card {
    width: 85%;
    vertical-align: middle; /*Vertically centered*/
  }
  .card-background .updatepolicy-card .form-card-new {
    width: 100%;
    margin-top: 50px;
  }
  .card-background .updatepolicy-card .input {
    box-sizing: border-box;
    width: 100%;
    font: normal 25px Poppins;
    height: 70px;
    border-radius: 35px;
  }
  .card-background .updatepolicy-card input .input {
    box-sizing: border-box;
    width: 100%;
    font: normal 25px Poppins;
    height: 140px;
    border-radius: 35px;
    border-style: none;
  }

  .card-background .updatepolicy-card .login-submit {
    box-sizing: border-box;
    width: 100%;
    font: normal 600 25px Poppins;
    height: 70px;
    border-radius: 35px;
  }

  .card-background .updatepolicy-card .reset {
    font: normal 25px Poppins;
  }

  .card-background .updatepolicy-card {
    height: fit-content;
  }
}
@media only screen and (max-width: 570px) {
  .card-background .updatepolicy-card .upload-pic .uploadTxt {
    font-size: 15px;
  }
  .card-background .updatepolicy-card .upload-pic .uploadbtn {
    width: 120px;
    height: 30px;
    font: normal 600 16px Poppins;
  }
  .card-background .updatepolicy-card .upload-pic {
    padding: 10px;
  }
  .card-background .updatepolicy-card h1 {
    height: 20px;
    font: normal normal bold 25px Poppins;
  }
  .card-background .updatepolicy-card .input {
    font: normal 20px Poppins;
    height: 60px;
  }
  .card-background .updatepolicy-card .login-submit {
    font: normal 20px Poppins;
    height: 60px;
    margin-top: 15px;
  }
  .card-background {
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.5);
    background-attachment: fixed;
  }
  .card-background .updatepolicy-card {
    width: 78%;
  }
}
