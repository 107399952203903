.mainNav {
  display: flex;
  top: 2.13%;
  width: 100%;
  position: relative;
}

/* =====Title=== */
.mainNav .title {
  box-sizing: border-box;
  left: 32px;
  width: fit-content;
  height: 57px;
  opacity: 1;
  position: relative;
}
.mainNav .title h1 {
  font: normal bold 41px Poppins;
  color: #000000;
  margin: 0;
}

/* =====profile=== */
.mainNav .profile {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  float: right;
  padding-right: 52px;
}
/* profile image */
.mainNav .profile .profilePic {
  box-sizing: border-box;
  vertical-align: text-top;
  position: relative;
  top: 0px;
  width: 60px;
  height: 60px;
}
.mainNav .profile .subTitle {
  box-sizing: border-box;
  position: relative;
  margin-left: 22px;
}
.mainNav .profile .subTitle h2 {
  box-sizing: border-box;
  font-weight: normal medium;
  font-size: 17px;
  color: #000000;
  margin: 0;
}
/* profile drop down button */
.mainNav .profile .drop img {
  box-sizing: border-box;
  position: relative;
  margin-left: 33px;
  width: 17px;
  height: 17px;
}
