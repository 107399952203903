.dropdown1 {
  overflow: hidden;
}

.dropbtn1 {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.img-down {
  position: absolute;
  right: 20px;
}
.dropdown1 .dropbtn1 {
  background-color: #fff;
  outline: none;
  font-family: inherit;
  margin: 0;
  width: 100%;
  height: 46px;
  border: 1px solid #afafaf;
  border-radius: 100px;
  padding-left: 22px;
  padding-right: 25px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  letter-spacing: 0px;
  background-position: right 22px center !important;
  letter-spacing: 0px;
  color: #212529aa;
  text-align: left;
  font: normal 14px Poppins;
  font-size: 14px;
}

.dropdown1:hover .dropbtn1 {
  background-color: #fff;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: 300px;
  z-index: 1;
  border: 1px solid #afafaf;
  border-radius: 0px 0px 24px 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 200px;
}
.dropdown-content1::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dropdown-content1 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dropdown-content1 a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  margin: 6px;
  background-color: #fff;
}
.dropdown-content1 {
  background-color: #fff;
}
.dropdown-content1 a:hover {
  background-color: #ddd;
  border-top: none;
  border-radius: 12px;
}
