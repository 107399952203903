/* react-confirm-alert-overlay */
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.56) !important;
  /*  rgba(255, 255, 255, 0.5) !important */
}
.Ractive {
  /* rgb(30, 70, 32) */
  color: #03a9f4;
  background-color: rgb(229, 246, 253);
  font-weight: 700;
  /* color: #fff;
  background-color: #32c232; */
  padding: 6px;
  border-radius: 12px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50;
}
.Rinactive {
  color: rgb(95, 33, 32);
  background-color: rgb(253, 237, 237);
  font-weight: 600;

  padding: 6px;
  border-radius: 12px;
  width: 120px;
  height: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputContainer i {
  position: absolute;
}
.inputContainer {
  width: 100%;
  margin-bottom: 10px;
}
.icon {
  padding: 15px;
  color: rgb(49, 0, 128);
  width: 70px;
  text-align: left;
}
.Field {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.rider-table {
  overflow-x: auto;
  /* top: 50px; */
  /* position: relative; */
  /* margin-left: 32px;
  margin-right: 52px; */
}
.main-div {
  width: 100% !important;
}
.tableHead2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 88px;
  /* left: 3%; */
  position: relative;
  padding-right: 52px;
}
table {
  border-width: 0px;
  border-spacing: 0;
  width: 100%;
  font-size: 14px;
}

.headclass {
  left: 32px;
  position: relative;
}
.table-rider {
  overflow-x: auto;
  max-width: 1100px;
  /* margin: 2rem auto; */
}

@media only screen and (max-width: 800px) {
  .table-rider {
    width: calc(1vw * 90);
  }
}

th {
  background-color: #501abf;
  color: #ffffff !important;
  font: normal 400 16px Poppins;
  padding: 0px;
}
th,
td {
  text-align: left;
  line-height: 54px;
  padding-left: 50px;
  padding-right: 50px;
}
td {
  padding-top: 30px;
}

.tableimg {
  width: 40px;
  height: 40px;
}
/* tr:nth-child(even){background-color: #f2f2f2} */

.btnaddnew {
  position: relative;
  margin-left: auto;
  width: 222px;
  height: 69px;
  border: 1px solid #501abf;
  border-radius: 44px;
  opacity: 1;
  background-color: #ffffff;
}
.subhead {
  color: #501abf;
}

.btntext {
  color: #501abf;
}

.tableHead2 .css-1oaj67-MuiTypography-root {
  left: 3%;
}
@media only screen and (max-width: 600px) {
  .tableHead2 {
    /* z-index: 9; */
  }

  .button-cus {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .slider .navigation {
    z-index: 9;
  }
  .btnaddnew {
    margin: 0px;
  }

  /* 
.tableHead2 {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 88px;
  left: 3%; 
  position: relative;
  padding-right: 52px;
} */

  .main .mainNav {
    top: 2.4%;
    width: 77%;
    left: 3%;
    align-items: center;
  }

  .mainNav .title {
    left: 0;
  }
  .mainNav .title h1 {
    font-size: 20px;
  }

  .mainNav .profile {
    padding-right: 30px;
  }

  .mainNav .profile .profilePic {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .mainNav .profile .drop img {
    margin-left: 15px;
  }
  .profilePic > img:nth-child(1) {
    width: 45px;
  }

  .mainNav .profile .subTitle {
    display: none;
  }

  .newFile2 {
    top: 20%;
    width: 80%;
  }

  .newFile2 form {
    margin-top: 50px;
  }
  .newFile2 form .input-div-input {
    height: 56px;
    border-radius: 35px;
    font: normal 21px Poppins;
    padding-right: 22px;
    padding-left: 40px;
  }
  .newFile2 form .input-div-botton {
    padding-right: 46px;
  }

  .newFile2 form .submit3 {
    height: 70px;
    border-radius: 35px;
    font: normal 21px Poppins;
  }

  /* Inline #7 | http://localhost:3000/rider */

  .headclass {
    /* left: 32px; */
    left: 3%;
  }

  .rider-table {
    /* margin-left: 32px; */
    left: 3%;
    margin-left: 0px;
  }
}
.rider-info {
  color: #501abf;
  font-size: 1.42rem;
}

.button-cus {
  color: #501abf;
  width: 170px;
  height: 45px;
  border: 1px solid #501abf;
  border-radius: 44px;
  background-color: #ffffff;
}
.rider-head-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.dotStatus {
  width: 12px;
  height: 12px;
  padding-left: 10px;
  border-radius: 50%;
}
.d-Ractive {
  background-color: #64b164;
}

.d-Rinactive {
  background-color: #0983c5;
}
