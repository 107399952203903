.rider-table2 {
  overflow-x: auto;
  top: 50px;
  position: relative;
  margin-left: 32px;
  margin-right: 52px;
}

table {
  width: 100%;
}

th {
  background-color: #501abf;
  color: #ffffff;
  font: normal 400 16px Poppins;
  padding: 0px;
}
th,
td {
  font-size: 13px;

  text-align: left;
  line-height: 20px;
  /* padding-left:50px; */
  /* padding-Right:50px/; */
}
td {
  padding-top: 30px;
}
/* tr:hover {
  transform: scale(1.01);
  -webkit-box-shadow: 0px 15px 20px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 15px 20px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 15px 20px -12px rgba(0, 0, 0, 0.75);
  cursor: pointer;
} */
.tableimg {
  width: 40px;
  height: 40px;
}
/* tr:nth-child(even){background-color: #f2f2f2} */

.tableHead3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 88px;
  margin-left: 32px;
  margin-right: 52px;
}
.btnaddnew2 {
  position: relative;
  margin-left: auto;
  width: 222px;
  height: 69px;
  border: 1px solid #501abf;
  border-radius: 44px;
  opacity: 1;
  background-color: #ffffff;
}
.subhead {
  color: #501abf;
}

.btntext {
  color: #501abf;
}

/* =======================================================
 ======================================================= */

/* Inline #11 | http://localhost:3000/setup */

/* Inline #5 | http://localhost:3000/setup */
.table-telecaller {
  overflow-x: auto;
  max-width: 1400px;
  margin: 2rem auto;
}
@media only screen and (max-width: 1200px) {
  .table-telecaller {
    width: calc(1vw * 90) !important;
  }
}
@media only screen and (max-width: 800px) {
  .table-telecaller {
    width: calc(1vw * 90) !important;
  }
  .mainNav .profile {
    padding-right: 20px;
  }
  .btnaddnew2 {
    position: relative;
    margin-left: auto;
    width: 120px;
    height: 40px;
    border: 1px solid #501abf;
    border-radius: 24px;
    opacity: 1;
    background-color: #ffffff;
  }
}
