body {
  margin: 0;
}
.main {
  position: absolute;
  display: flex;
  width: 100%;
}

.main .container {
  position: relative;
  height: 1080px;
  width: 100%;
}

/* ==========form=========== */

.newFile2 {
  max-width: 1000px;
}

.newFile2 form {
  position: relative;
  margin-top: 2rem;
}

.newFile2 form .input-div-3 {
  width: 100%;
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding-left: 44px;
  padding-right: 43px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  letter-spacing: 0px;
  position: absolute;
  opacity: 0;
}
[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-text);
}
.newFile2 form .input-div-2 {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding-left: 44px;
  padding-right: 43px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  letter-spacing: 0px;
  color: #afafaf;
  text-align: left;
  font: normal 30px Poppins;
}

.newFile2 form .input-div {
  position: relative;
  width: 100%;
}

.newFile2 form .input-div-input {
  width: 100%;
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding-left: 22px;
  padding-right: 22px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  letter-spacing: 0px;
  color: #afafaf;
  text-align: left;
  font: normal 30px Poppins;
  font-size: 14px !important;
}

.newFile2 form .input-div-botton {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  margin: 0;
  padding-right: 22px;
  border-radius: 100px;
  z-index: 1;
  top: 33.33%;
  width: 40px;
}

.newFile2 form .submit3 {
  width: 400px;
  height: 45px;
  margin-top: 35px;
  background: #5016bf 0% 0% no-repeat padding-box;
  border-radius: 44px;
  font-size: 16px;
  font-family: Poppins;
  color: #ffffff;
  border: none;
  font-size: 500;
}

/* ============================================ */

@media only screen and (max-width: 1120px) {
  .main .mainNav {
    top: 3%;
    width: 94%;
    left: 3%;
  }

  .mainNav .profile {
    padding-right: 20px;
  }
  .mainNav .title h1 {
    font: normal bold 30px Poppins;
    color: #000000;
    margin: 0;
  }

  .main .container {
    left: 0;
    width: 100%;
  }

  .newFile2 form {
    width: 100%;
    margin-top: 50px;
  }
  .newFile2 form .input-div {
    box-sizing: border-box;
    width: 100%;
  }
  .newFile2 form .submit3 {
    box-sizing: border-box;
    width: 100%;
  }
  .newFile2 form .submit3 {
    height: 70px;
    font-size: normal 500 25px Poppins;
    font: normal normal 500 25px Poppins;
    max-width: 400px;
  }
}

/* =========================================== */

@media only screen and (max-width: 800px) {
  .slider .navigation {
    z-index: 9;
  }

  .main .mainNav {
    top: 3.4%;
    width: 97%;
    left: 3%;
  }

  .mainNav .title {
    left: 0;
  }
  .mainNav .title h1 {
    font-size: 20px;
  }

  .mainNav .profile {
    padding-right: 30px;
  }

  .mainNav .profile .profilePic {
    width: fit-content;
    height: fit-content;
  }
  .mainNav .profile .drop img {
    margin-left: 15px;
  }
  .profilePic > img:nth-child(1) {
    width: 45px;
  }

  .mainNav .profile .subTitle {
    display: none;
  }

  .newFile2 form {
    margin-top: 50px;
  }

  .newFile2 form .input-div-botton {
    padding-right: 22px;
  }

  .newFile2 form .submit3 {
    height: 70px;
    border-radius: 35px;
    font: normal 21px Poppins;
  }
}
