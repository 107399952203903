:root {
  --landscape-width: 20;
  --portrait-width: 80vw;
  --header-height: 4rem;
  --secs: 0.4s;
  --bg-left: #ffffff;
  --bg-right: #ffffff;
  --bg-main: #ffffff;
  --bg-main-icon: #5016bf;
}
body {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  overflow: hidden;
  font-family: Poppins;
}
#layout {
  display: flex;
  overflow: hidden;
}
#layout .header {
  position: relative;
  height: var(--header-height);
  text-align: center;
}
.header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 4px 24px !important;
}
.title {
  font-size: 30px;
}
#layout .header .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 3rem;
  font-family: "Poppins";
  font-weight: 900;
}
#layout .content {
  overflow: auto;
  box-sizing: border-box;
  height: calc(100vh - var(--header-height));
}
.content2 {
  padding: 1rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem;
  overflow: auto;
  box-sizing: border-box;
  height: calc(100vh);
}
#main {
  background: var(--bg-main);
  flex-grow: 1;
}
#main .header {
  background: linear-gradient(to right, var(--bg-left), var(--bg-right));
}
@media (orientation: landscape) {
  #main .header .title {
    transition: left var(--secs), right var(--secs);
  }
  #main .header .title.left-closed {
    left: var(--header-height);
    margin: 0px 0px -6px 0px;
  }
  #main .header .title.left-closed {
    left: var(--header-height);
    margin: 0px 0px 0px 64px;
  }
  #main .header .title.right-closed {
    right: var(--header-height);
  }
}
#left,
#right {
  transition: width var(--secs);
  width: 0;
  box-shadow: 5px 5px 55px #0000000d;
}

@media (orientation: landscape) {
  #left.open,
  #right.open {
    width: calc(1% * var(--landscape-width));
  }
}
#left .icon,
#right .icon {
  color: var(--bg-main-icon);
  position: fixed;
  z-index: 10;
  width: var(--header-height);
  height: var(--header-height);
  line-height: var(--header-height);
  font-size: var(--header-height);
  text-align: center;
  user-select: none;
  cursor: pointer;
}
#left .sidebar,
#right .sidebar {
  transition: transform var(--secs);
}
@media (orientation: portrait) {
  .content2 {
    padding-left: 2rem !important;
    padding-top: 6rem !important;
  }
  #left .sidebar,
  #right .sidebar {
    width: var(--portrait-width);
  }
  .header {
    justify-content: flex-end !important;
  }
  .title {
    display: none !important;
    font-size: 20px !important;
  }
}
@media (orientation: landscape) {
  #left .sidebar,
  #right .sidebar {
    width: calc(1vw * var(--landscape-width));
  }
}
#left .sidebar .header,
#right .sidebar .header {
  width: calc(100% - var(--header-height));
}

#left {
  z-index: 5;
}
#left .sidebar {
  background: var(--bg-left);
}
#left .sidebar.closed {
  transform: translateX(-100%);
}
#left .sidebar .header {
  left: var(--header-height);
}
#right .icon {
  right: 0;
}
.icon {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
}
#right .sidebar {
  background: var(--bg-right);
}
@media (orientation: portrait) {
  #right .sidebar.open {
    transform: translateX(-100%);
  }
}
.side-options {
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}
.side-option:hover {
  background: #5016bf;
  padding: 0px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  color: #ffffff;
  opacity: 0.6;
}
.left-heading {
  background: #ffffff;
  margin: 10px 0px;
  padding: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  color: #9b9b9b;
  font-weight: 400 !important;
  position: relative;
}

.left-heading > span {
  font-size: 13px !important;
}
@media only screen and (min-width: 1700px) {
  .left-heading > span {
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 1100px) {
  .left-heading {
    margin: 2px 0px;
    height: 46px;
  }
}
.left-heading > span {
  text-transform: capitalize;
}
.icon-arrow-setup {
  position: absolute;
  right: 0;
  padding-right: 10px;
}
.left-heading-profile {
  margin: 10px 0px;
  padding: 0px;
  height: 72px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
}
.left-heading:hover {
  background-color: #5016bf9b !important;
  color: #fff;
}
.side-option {
  margin: 4px 0px;
  padding: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  color: #9b9b9b;
}
.main-right-profile {
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 17px;
}
.main-right-profile-name {
  color: #000000;
  letter-spacing: 0px;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

/* add style to side navigation */
.navigation .nav-menu.active .nav-links .nav-option {
  padding-left: 40px;
  width: auto;
  height: 72px;
  align-items: center;
  display: flex;
  color: #9b9b9b;
  margin: 2px 0px;
}
.actived {
  background-color: #5016bf !important  ;
  color: #fff;
}
.s-active {
  background-color: #5016bf9b !important  ;
  color: #fff;
}
.nav-option:hover {
  background-color: #5016bfaa !important;
  color: #fff !important;
  font-weight: 600;
}
.navigation .nav-menu.active .nav-links .nav-option.active {
  color: #ffffff;
  background: #5016bf 0% 0% no-repeat padding-box;

  width: 100%;
  padding-left: 40px;
  height: 72px;
  align-items: center;
  display: flex;
}
.menubtn {
  position: relative;
  height: 80px;
  justify-content: start;
  align-items: center;
}
.mainNav {
  display: flex;
  top: 2.13%;
  width: 100%;
}

/* =====Title=== */
.mainNav .title {
  box-sizing: border-box;
  left: 32px;
  width: fit-content;
  height: 57px;
  opacity: 1;
}
.mainNav .title h1 {
  font: normal bold 41px Poppins;
  color: #000000;
  margin: 0;
}

/* =====profile=== */
.mainNav .profile {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  float: right;
  padding-right: 52px;
}
/* profile image */
.mainNav .profile .profilePic {
  box-sizing: border-box;
  vertical-align: text-top;
  top: 0px;
  width: 60px;
  height: 60px;
}
.mainNav .profile .subTitle {
  box-sizing: border-box;
  position: relative;
  margin-left: 22px;
}
.mainNav .profile .subTitle h2 {
  box-sizing: border-box;
  font-weight: normal medium;
  font-size: 17px;
  color: #000000;
  margin: 0;
}
/* profile drop down button */
.mainNav .profile .drop img {
  box-sizing: border-box;
  position: relative;
  margin-left: 33px;
  width: 17px;
  height: 17px;
}
/* main-content-navbar */
@media only screen and (min-width: 1100px) {
  .main-content-navbar {
    background-color: red !important;
    display: none !important;
  }
}
.main-content-navbar {
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 60px;
}
.input-div {
  position: relative;
  width: 100%;
}

.input-div-input {
  width: 100%;
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding-left: 22px;
  padding-right: 22px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  letter-spacing: 0px;
  color: #afafaf;
  text-align: left;
  font: normal 30px Poppins;
  font-size: 14px !important;
}
